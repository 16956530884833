<template>
  <BtnGradient v-if="isAuthenticated" class="btn-enter" @click="goToCabinet"> Cabinet </BtnGradient>
  <BtnGradient v-else class="btn-enter" @click="goToAuth">Sign In / Sign Up</BtnGradient>
</template>

<script>
  import BtnGradient from '@/prerender-pages/new/components/shared/btnEnter/private/BtnGradient';
  import { AuthService } from '@/services/auth.service.ts';

  export default {
    name: 'BtnEnter',

    components: { BtnGradient },

    data: () => ({
      isAuthenticated: AuthService.isAuthenticated(),
    }),

    methods: {
      goToAuth() {
        window.location.href = '/auth/login/';
      },
      goToCabinet() {
        window.location.href = '/app/devices/';
      },
    },
  };
</script>
