<template>
  <div class="links-group">
    <div class="links-group__title">{{ title }}</div>
    <a v-for="{ url, title } of links" :key="url" :href="url" class="links-group__link">
      <span class="links-group__link-text">{{ title }}</span>
    </a>
  </div>
</template>

<script>
  export default {
    name: 'LinksGroup',

    props: {
      title: {
        type: String,
        required: true,
      },
      links: {
        type: Array,
        required: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .links-group {
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: $landing-size-xs;

    &__title {
      margin-bottom: 20px;
      font-weight: $weight-semi-bold;
    }

    &__link {
      margin-bottom: 14px;
    }

    &__link-text {
      border-bottom: 1px solid transparent;
      transition: border-bottom-color 0.3s;

      &:hover {
        border-bottom-color: $color-text-default;
      }
    }
  }
</style>
