<template>
  <a :href="link" class="menu-item" :class="[classActive, classWithHighlight]">
    <span class="menu-item__content" :class="{ 'menu-item__content--underline': hasUnderline }"
      ><slot
    /></span>
  </a>
</template>
<script>
  export default {
    name: 'MenuItem',

    props: {
      link: {
        type: [String, Object],
        required: true,
      },
      hasHighlight: {
        type: Boolean,
        default: true,
      },
      hasUnderline: {
        type: Boolean,
        default: true,
      },
    },

    computed: {
      classActive() {
        return window.location.pathname === this.link && 'menu-item--active';
      },
      classWithHighlight() {
        return this.hasHighlight && 'menu-item--has-highlight';
      },
    },
  };
</script>

<style lang="scss" scoped>
  .menu-item {
    display: flex;
    align-items: center;
    height: 100%;
    color: $color-text-default;
    font-weight: $weight-bold;
    font-size: $landing-size-sm;
    padding: 0 10px;
    transition: background-color 0.3s;
    white-space: nowrap;

    &--has-highlight {
      &:hover,
      &.menu-item--active {
        background-color: #dbdada;
      }
    }

    &:hover:not(&--has-highlight) &__content--underline {
      border-bottom-color: $color-text-default;
    }

    @media (max-width: $screen-xs-max) {
      font-size: $landing-size-xs;
      padding: 0 8px;
      font-weight: $weight-medium;
    }

    &__content {
      border-bottom: 1px solid transparent;
      transition: border-bottom-color 0.3s;
    }
  }
</style>
