<template>
  <footer class="footer">
    <ContentContainer class="footer__container">
      <div class="footer__links">
        <LinksGroup title="Shop Hardware Kits" :links="links.shopHardwareKits" />
        <LinksGroup title="Shop Hardware" :links="links.shopHardware" />
        <LinksGroup title="HPLC Cloud" :links="links.hplcCloud" />
        <div class="footer__wrapper-links-group">
          <LinksGroup
            title="For business"
            :links="links.forBussines"
            class="footer__links-group--for-bussines"
          />
          <LinksGroup title="For education" :links="links.forEducation" />
        </div>
        <LinksGroup title="About Newcrom" :links="links.aboutNewcrom" />
      </div>

      <div class="footer__contact">
        To contact directly email at
        <a href="mailto:support@sielc.com" class="footer__link-contact">support@sielc.com</a>. Or
        call
        <a href="tel:+18472292629" class="footer__link-contact">+1 847 229-2629</a>
      </div>

      <div class="footer__divider"></div>

      <Copyright class="footer__copyright" />
    </ContentContainer>
  </footer>
</template>

<script>
  import ContentContainer from '../../shared/ContentContainer';
  import LinksGroup from './private/LinksGroup';
  import links from './private/links';
  import Copyright from './private/Copyright';

  export default {
    name: 'Footer',

    components: { Copyright, LinksGroup, ContentContainer },

    data: () => ({
      links,
    }),
  };
</script>

<style lang="scss" scoped>
  .footer {
    background-color: #dedede;
    padding: 55px 0;
    font-size: $landing-size-xs;

    &__links {
      display: grid;
      gap: 20px 66px;
      grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
      margin-bottom: 50px;

      @media (max-width: $screen-xs-max) {
        gap: 20px 10px;
      }
    }

    &__links-group {
      &--for-bussines {
        margin-bottom: 20px;
      }
    }

    &__wrapper-links-group {
      display: flex;
      flex-direction: column;
    }

    &__link-contact {
      white-space: nowrap;

      &:hover {
        text-decoration: underline;
      }
    }

    &__contact {
      line-height: 1.5;

      @media (max-width: $screen-xs-max) {
        text-align: center;
      }
    }

    &__divider {
      height: 1px;
      width: 100%;
      background-color: black;
      margin: 15px 0;
    }
  }
</style>
