<template>
  <header class="header">
    <ContentContainer class="header__menu">
      <div class="header__section header__section--products">
        <MenuItem link="/" class="header__menu-item" :hasHighlight="false" :hasUnderline="false">
          <img
            src="@/prerender-pages/new/assets/images/layout/hplc_logo.png"
            alt="hplc logo"
            class="header__logo"
          />
        </MenuItem>
        <MenuItem link="/" class="header__menu-item" :hasHighlight="hasHighlight"
          >HPLC Cloud</MenuItem
        >
        <!--        <MenuItem link="/features" class="header__menu-item">Features</MenuItem>-->
        <!--        <MenuItem link="/documentation" class="header__menu-item">Documentation</MenuItem>-->
      </div>
      <div class="header__section header__section--contact">
        <MenuItem link="/contacts/" class="header__menu-item" :hasHighlight="hasHighlight"
          >Support</MenuItem
        >
        <BtnEnter class="header__btn-enter" />
      </div>
    </ContentContainer>
  </header>
</template>

<script>
  import ContentContainer from '../../shared/ContentContainer';
  import MenuItem from './private/MenuItem';
  import BtnEnter from '@/prerender-pages/new/components/shared/btnEnter/BtnEnter';

  export default {
    name: 'Header',

    components: {
      BtnEnter,
      ContentContainer,
      MenuItem,
    },

    props: {
      hasHighlight: {
        type: Boolean,
        default: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .header {
    width: 100%;
    height: 50px;
    display: flex;

    &__menu {
      flex: 1;
      display: flex;
      justify-content: space-between;
      width: 100%;

      @media (max-width: $screen-xs-max) {
        padding: 0;
      }

      @media (max-width: 440px) {
        overflow-x: auto;
      }
    }

    &__section {
      display: flex;
    }

    &__logo {
      width: 40px;
      height: 40px;
    }

    &__menu-item {
      &:not(:last-child) {
        margin-right: 28px;

        @media (max-width: $screen-md-max) {
          margin-right: 26px;
        }

        @media (max-width: $screen-sm-max) {
          margin-right: 20px;
        }

        @media (max-width: $screen-xs-max) {
          margin-right: 0;
        }
      }
    }

    &__btn-enter {
      align-self: center;
      width: 188px;
      margin-left: 20px;

      @media (max-width: $screen-xs-max) {
        width: auto;
        height: 22px;
        margin: 0 5px;
        font-size: 1.4rem;
      }
    }
  }
</style>
