<template>
  <div class="copyright">
    <div class="copyright__message">Copyright 2023 © Newcrom Corp. All rights reserved.</div>
    <div class="copyright__agreements">
      <a href="/terms#privacy" class="copyright__agreement">Privacy Policy</a>
      <span class="copyright__agreements-divider">|</span>
      <a href="/terms" class="copyright__agreement">Terms of Use</a>
    </div>
    <div class="copyright__country">United States</div>
  </div>
</template>

<script>
  export default {
    name: 'Copyright',
  };
</script>

<style lang="scss" scoped>
  .copyright {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: $landing-size-xs;

    @media (max-width: $screen-xs-max) {
      flex-direction: column;
      height: 60px;
    }

    &__message {
      //margin-right: 60px;
    }

    &__agreements-divider {
      margin: 0 7px;
    }

    &__agreement {
      &:hover {
        text-decoration: underline;
      }
    }

    &__country {
      width: 330px;
      text-align: right;
      //margin-left: auto;

      @media (max-width: $screen-md-max) {
        width: auto;
      }

      //@media (max-width: $screen-sm-max) {
      //  width: 240px;
      //}
    }
  }
</style>
