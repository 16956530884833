<template>
  <div class="content-container">
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'ContentContainer',
  };
</script>

<style lang="scss" scoped>
  .content-container {
    margin: 0 auto;
    width: 100%;
    max-width: $landing-content-container-max-width;
    padding: 0 32px;

    @media (max-width: $screen-sm-max) {
      padding: 0 15px;
    }
  }
</style>
