const createLinkToLanding = (path: string) => `https://newcrom.com${path}`;

type Link = {
  title: string;
  url: string;
};

const shopHardwareKits: Link[] = [
  {
    title: 'Alltesta Basic',
    url: createLinkToLanding('/shop#basic'),
  },
  {
    title: 'Alltesta Basic Automated',
    url: createLinkToLanding('/shop#basic'),
  },
  {
    title: 'Alltesta Gradient',
    url: createLinkToLanding('/shop#gradient'),
  },
  {
    title: 'Alltesta Gradient Flip',
    url: createLinkToLanding('/shop#gradient'),
  },
];

const shopHardware: Link[] = [
  {
    title: 'Syringe Pump',
    url: createLinkToLanding('/shop#pump'),
  },
  {
    title: 'Autosampler',
    url: createLinkToLanding('/shop#autosampler'),
  },
  {
    title: 'Valve',
    url: createLinkToLanding('/shop#valve'),
  },
  {
    title: 'UV Vis Detector',
    url: createLinkToLanding('/shop#detector'),
  },
];

const hplcCloud: Link[] = [
  {
    title: 'Manage account',
    url: 'https://hplc.cloud/app/account/',
  },
  {
    title: 'Get support',
    url: '/contacts/',
  },
  // {
  //   title: 'Features',
  //   url: '#',
  // },
  // {
  //   title: 'Documentation',
  //   url: '#',
  // },
];

const forBussines: Link[] = [
  {
    title: 'Newcrom and business',
    url: createLinkToLanding('/'),
  },
  {
    title: 'Shop for business',
    url: createLinkToLanding('/'),
  },
];

const forEducation: Link[] = [
  {
    title: 'Newcrom and education',
    url: createLinkToLanding('/'),
  },
  {
    title: 'Shop for education',
    url: createLinkToLanding('/'),
  },
];

const aboutNewcrom: Link[] = [
  {
    title: 'Newsroom',
    url: createLinkToLanding('/'),
  },
  {
    title: 'Contact Newcrom',
    url: createLinkToLanding('/contacts'),
  },
  {
    title: 'Career Opportunities',
    url: createLinkToLanding('/contacts#carrer'),
  },
  {
    title: 'Newcrom Leadership',
    url: createLinkToLanding('/'),
  },
];

export default {
  shopHardwareKits,
  shopHardware,
  hplcCloud,
  forBussines,
  forEducation,
  aboutNewcrom,
};
