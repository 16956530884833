<template>
  <div v-show="isShowPage" class="layout-public" :class="classMenuOverlay">
    <Header class="layout-public__header" :hasHighlight="!isHomePage" />
    <RouterView />
    <Footer />
  </div>
</template>

<script>
  import Header from '@/prerender-pages/new/components/layout/header/Header';
  import Footer from '@/prerender-pages/new/components/layout/footer/Footer';

  export default {
    name: 'LayoutPublic',

    components: {
      Footer,
      Header,
    },

    computed: {
      isHomePage() {
        return this.$route.name === 'home';
      },
      classMenuOverlay() {
        return {
          'layout-public--menu-overlay': !this.isHomePage,
        };
      },
      isShowPage() {
        // To prevent blink if redirect
        return !this.$route.meta.redirect;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .layout-public {
    &--menu-overlay {
      padding-top: 50px;

      @media (max-width: $screen-xs-max) {
        padding-top: 40px;
      }
    }

    &__header {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }
</style>
