<template>
  <button class="btn-gradient" v-on="$listeners">
    <slot />
  </button>
</template>

<script>
  export default {
    name: 'BtnGradient',
  };
</script>

<style lang="scss" scoped>
  .btn-gradient {
    height: 32px;
    color: white;
    font-weight: $weight-semi-bold;
    border-radius: 22px;
    padding: 0 10px;
    background: linear-gradient(92.09deg, #ff259b -16.13%, #11129d 133.01%), #c4c4c4;
    white-space: nowrap;

    &:hover {
      background: linear-gradient(92.09deg, #ff589b -16.13%, #3a00d9 133.01%), #c4c4c4;
    }

    &:active {
      color: #adb1be;
      background: linear-gradient(92.09deg, #db0077 -16.13%, #141573 133.01%), #c4c4c4;
    }
  }
</style>
